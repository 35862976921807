<template>
    <div class="container-fluid">
        <div class="col-md-12">
            <h4 class="title-in-h">{{info_page.header}}</h4>
        </div>
        <breadcrumb >
            <breadcrumb-item  v-for="breadcrumb in breadcrumbs" v-if="breadcrumb.type!=='active'">
                <router-link :to="{path:breadcrumb.path}" class="color-blue-var" >{{breadcrumb.name}}</router-link>
            </breadcrumb-item>
            <breadcrumb-item active v-else>
                {{breadcrumb.name}}
            </breadcrumb-item>
        </breadcrumb>

        <card class="mt-2" v-loading="screenLoading"
              element-loading-lock="true"
              :element-loading-text="screenText"
              element-loading-customClass="loading-large"
              element-loading-spinner="el-icon-loading">
            <div>
                <form>
                    <div class="row">

                        <div class="col-md-4">
                            <fg-input type="text"
                                      :name="$t('form.name')"
                                      @input="annulment.name = annulment.name.toUpperCase()"
                                      :label="$t('form.name')"
                                      placeholder=""
                                      v-validate="modelValidations.name"
                                      :error="getError($t('form.name'))"
                                      v-model="annulment.name">
                            </fg-input>

                        </div>
                        <div class="col-md-8">
                            <div class="form-check text-none"  >
                                <label for="test" class="form-check-label inline-form">
                                    <input class="form-check-input" type="checkbox" id="test" v-model="annulment.main" value=true>
                                    <span class="form-check-sign" ></span>{{$t('annulment.form.politic_base')}}

                                </label>
                            </div>
                        </div>

                    </div>

                    <div class="row">

                        <div class="col-md-12">
                            <h4 class="h-title">
                                {{$t('annulment.form.config_calleation')}}
                            </h4>
                            <div class="form-check  text-none"  >
                                <label for="test2" class="form-check-label color-warning">
                                    <input class="form-check-input" type="checkbox" id="test2" v-model="annulment.no_refundable" value=true @change="annulment.refundable = !annulment.no_refundable">
                                    <span class="form-check-sign" ></span>{{$t('annulment.form.rate_refundable')}}
                                </label>
                            </div>

                        </div>
                    </div>

                    <div class="row" v-if="annulment.refundable">
                        <div class="col-md-4">
                            <label >{{$t('annulment.form.period')}}</label>
                            <fg-input
                                    v-validate="modelValidations.period" :name="$t('annulment.form.period_valid')"
                                    v-model="annulment.cancellation_time"  :error="getError($t('annulment.form.period_valid'))">
                                <el-select class="select-default" size="large"  :name="$t('contract.form.type')"
                                           v-model="annulment.cancellation_time">
                                    <el-option v-for="(option, idx ) in info_page.cancellation_times" class="select-default" :value="option.value"
                                               :label="option.name" :key="idx">
                                    </el-option>
                                </el-select>
                            </fg-input>

                        </div>
                        <div class="col-md-4">
                            <label >{{$t('annulment.form.inside')}}</label>
                            <fg-input
                                    v-validate="modelValidations.inside_time" :name="$t('annulment.form.inside_valid')"
                                    v-model="annulment.in_id"  :error="getError($t('annulment.form.inside_valid'))">

                                <el-select class="select-default" size="large" :placeholder="$t('contract.form.type')"  v-validate="modelValidations.period" :name="$t('annulment.form.period_valid')"
                                           v-model="annulment.in_id"  :error="getError($t('contract.form.type'))">
                                    <el-option v-for="(option, idx ) in info_page.period_types" class="select-default" :value="option._id"
                                               :label="option.name" >
                                    </el-option>
                                </el-select>
                            </fg-input>
                        </div>
                        <div class="col-md-4">
                            <label >{{$t('annulment.form.outside')}}</label>
                            <fg-input
                                    v-validate="modelValidations.outside_time" :name="$t('annulment.form.outside_valid')"
                                    v-model="annulment.out_id"  :error="getError($t('annulment.form.outside_valid'))">

                                <el-select class="select-default" size="large" :placeholder="$t('contract.form.type')"  v-validate="modelValidations.type" :name="$t('contract.form.type')"
                                           v-model="annulment.out_id"  :error="getError($t('contract.form.type'))">
                                    <el-option v-for="(option, idx ) in info_page.period_types" class="select-default" :value="option._id"
                                               :label="option.name">
                                    </el-option>
                                </el-select>
                            </fg-input>
                        </div>
                    </div>

                    <div class="row" v-if="annulment.refundable">
                        <div class="col-md-12">
                            <h4 class="h-title">
                                {{$t('annulment.form.refundable_exception')}} <span class="fa fa-exclamation-circle"></span>
                            </h4>

                        </div>
                        <div class="col-md-12">
                            <!--<fg-input>
                                <el-date-picker
                                        v-model="annulment.name"
                                        type="daterange"
                                        range-separator="To"
                                        start-placeholder="Start date"
                                        end-placeholder="End date">
                                </el-date-picker>
                            </fg-input>-->
                            <div class="box-items" v-for="(item, idx) in annulment.dates_no_refundables">
                                <div class="row ">
                                    <div class="col-md-11 ">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <fg-input>
                                                    <el-date-picker v-model="item.from" type="daterange" placeholder="Inicio"
                                                                    format="dd-MM-yyyy"
                                                                    :picker-options="pickerOptions1"
                                                                    range-separator="a"
                                                                    start-placeholder="Inicio"
                                                                    end-placeholder="Fin"
                                                    >
                                                    </el-date-picker>
                                                </fg-input>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-1">
                                        <a class="item-minus" v-tooltip.bottom="'Eliminar fecha'">
                                            <i class="fa fa-minus"  @click="deleteDate('refundable', idx)"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="form-check  mt-2 ml-3"  >
                                <label class="text-info color-blue-var" @click="addDate('refundable')">
                                    + {{$t('annulment.form.intval_refundable')}}
                                </label>
                            </div>
                        </div>

                    </div>
                    <div class="row" v-if="annulment.refundable">
                        <div class="col-md-12">
                            <h4 class="h-title">
                                {{$t('annulment.form.season_exception')}} <span class="fa fa-exclamation-circle"></span>
                            </h4>
                        </div>

                        <div class="col-md-12">
                            <div class="box-header"  v-show="annulment.dates_exception.length">
                                <div class="row">
                                    <div class="col-11 pb-0">
                                        <div class="row">
                                            <div class="col-4">

                                            </div>
                                            <div class="col-2 pb-0">
                                                <label class="mb-0">{{$t('annulment.form.period')}}</label>
                                            </div>
                                            <div class="col-3 pb-0">
                                                <label class="mb-0">{{$t('annulment.form.inside')}}</label>
                                            </div>
                                            <div class="col-3 pb-0">
                                                <label class="mb-0">{{$t('annulment.form.outside')}}</label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="box-items " v-for="(item, idx2) in annulment.dates_exception">
                                <div class="row">
                                    <div class="col-md-11 pt-0 pb-0">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <fg-input>
                                                    <el-date-picker v-model="item.from" type="daterange" placeholder="Inicio"
                                                                    format="dd-MM-yyyy"
                                                                    :picker-options="pickerOptions1"
                                                                    range-separator="a"
                                                                    start-placeholder="Inicio"
                                                                    end-placeholder="Fin"
                                                    >
                                                    </el-date-picker>
                                                </fg-input>
                                            </div>
                                            <div class="col-md-2">
                                                <fg-input v-model="item.cancellation_time" >
                                                    <el-select class="select-default" size="large"  :name="$t('contract.form.type')"
                                                               v-model="item.cancellation_time">
                                                        <el-option v-for="(option, idx ) in info_page.cancellation_times" class="select-default" :value="option.value"
                                                                   :label="option.name" :key="idx">
                                                        </el-option>
                                                    </el-select>
                                                </fg-input>
                                            </div>
                                            <div class="col-md-3">
                                                <fg-input v-model="item.in_id">

                                                    <el-select class="select-default" size="large" :placeholder="$t('contract.form.type')" v-model="item.in_id">
                                                        <el-option v-for="(option, idx ) in info_page.period_types" class="select-default" :value="option._id"
                                                                   :label="option.name" >
                                                        </el-option>
                                                    </el-select>
                                                </fg-input>
                                            </div>
                                            <div class="col-md-3">
                                                <fg-input v-model="item.out_id">

                                                    <el-select class="select-default" size="large" :placeholder="$t('contract.form.type')" v-model="item.out_id">
                                                        <el-option v-for="(option, idx ) in info_page.period_types" class="select-default" :value="option._id"
                                                                   :label="option.name">
                                                        </el-option>
                                                    </el-select>
                                                </fg-input>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="col-md-1">
                                        <a class="item-minus" v-tooltip.bottom="'Eliminar fecha'">
                                            <i class="fa fa-minus"  @click="deleteDate('exception', idx2)"></i>
                                        </a>
                                    </div>
                                </div>

                            </div>
                            <div class="box-headers pl-3">
                                <div class="row">
                                    <div class="col-12 pl-3 custom-error">
                                        <label class="text-danger "  v-show="annulment.errors.dates_exception">Se deben llenar todos los campos antes de continuar </label>
                                    </div>
                                </div>
                            </div>

                            <div class="form-check mt-2 ml-3"  >
                                <label class="text-info color-blue-var"  @click="addDate('exception')">
                                    + {{$t('annulment.form.intval_season')}}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <fg-input
                                    :label="$t('annulment.form.description')"  :name="$t('form.description')"  v-model="annulment.description"
                                    v-validate="modelValidations.description"
                                    :error="getError($t('form.description'))">
                            <textarea class="form-control" :placeholder="$t('form.description')"
                                      v-model="annulment.description" rows="7"></textarea>
                            </fg-input>
                        </div>
                    </div>

                    <div class="text-center mt-3 mb-3">
                        <button type="submit" class="btn btn-success btn-fill btn-wd mr-2" @click.prevent="sendForm(info_page.action)">
                            {{info_page.button}}
                        </button>
                        <button type="buttton" class="btn btn-danger btn-fill btn-wd" @click.prevent="$router.push({name: 'annulments'})">
                            {{$t('form.cancel')}}
                        </button>
                       <!-- <button type="buttton" class="btn btn-danger btn-fill btn-wd ml-2" @click.prevent="checkConfigsActives()">
                            Activos
                        </button>-->

                    </div>
                    <div class="clearfix"></div>
                </form>
            </div>
        </card>
    </div>
</template>
<script>
    import swal from 'sweetalert2'
    import {Select, Option, Tag, DatePicker, Collapse, CollapseItem} from 'element-ui'
    import { Checkbox, Breadcrumb, BreadcrumbItem } from 'src/components/index'
    import AnnulmentService from '../../../js/services/AnnulmentService';
    import ContractService from '../../../js/services/ContractService';
    import RoomService from '../../../js/services/RoomService';
    import LogFunctions from '../../../js/functions/LogFunctions';
    import LogService from '../../../js/services/LogService';
    import {VTab, VueTabs} from 'vue-nav-tabs'
    import Fuse from 'fuse.js'
    import EventBus from "../../../js/helpers/EventBus";
    import { vueTopprogress } from 'vue-top-progress';
    import moment from 'moment';


    const annulmentService = new AnnulmentService();
    const contractService = new ContractService();
    const roomService = new RoomService();
    import { mapGetters } from "vuex";
    import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'
    const logFunctions = new LogFunctions();
    const logService = new LogService();


    export default {
        components: {
            [DatePicker.name]: DatePicker,
            Checkbox,
            Breadcrumb,
            BreadcrumbItem,
            [Collapse.name]: Collapse,
            [CollapseItem.name]: CollapseItem,
            [Option.name]: Option,
            [Select.name]: Select,
            [Tag.name]: Tag,
            vueTopprogress
        },
        computed: {
          ...mapGetters(["currentUser","selected_property"]),
        },
        data() {
            return {
                screenLoading: false,
                screenText: '',
                breadcrumbs:[
                    {
                        name: this.$t('property.index.home'),
                        path: '/admin/properties',
                        type: '',
                    },

                    {
                        name: this.$t('annulment.index.title'),
                        path: '/admin/annulments',
                        type: '',
                    },

                    {
                        name: this.$t('annulment.new.header'),
                        path: '',
                        type: 'active',
                    }

                ],

                datePicker: '',
                pickerOptions1: {
                    /*disabledDate(time) {
                        console.log('Date.now()',  moment('08-01-2020'), time);
                        return time.getTime() < moment('08-01-2020', "DD-MM-YYYY");
                    },*/

                },
                log_page:{
                    action: null,
                    section: null,
                    property_id: null,
                    data: [],
                    date: new Date(),
                    object_id: null,
                    user_id: null,
                },

                info_page:{
                    original_info:{},
                    section_name: 'politic',
                    action: 'add',
                    property_id: '',
                    header: this.$t('annulment.new.title'),
                    button:  this.$t('form.save'),
                    tab_lang: 'es',
                    langs:[{
                        name: this.$t('locale_es'),
                        locale: 'es'
                    },{
                        name: this.$t('locale_en'),
                        locale: 'en'
                    },
                    ],

                    model_dates: {
                        _id: null,
                        from: ['',''],
                        to: ''
                    },


                    model_date_exception: {
                        _id: null,
                        from: ['',''],
                        to: '',
                        cancellation_time: '',
                        in_id: '',
                        out_id: ''
                    },

                    cancellation_times:[
                        {
                            name: '2 horas',
                            value: 2,
                        },
                        {
                            name: '6 horas',
                            value: 6,
                        },
                        {
                            name: '8 horas',
                            value: 8,
                        },
                        {
                            name: '24 horas',
                            value: 24,
                        },
                        {
                            name: '48 horas',
                            value: 48,
                        },
                        {
                            name: '72 horas',
                            value: 72,
                        },
                        {
                            name: '4 días (96 horas)',
                            value: 96
                        },
                        {
                            name: '5 días (120 horas)',
                            value: 120
                        },
                        {
                            name: '6 días (144 horas)',
                            value: 144
                        },
                        {
                            name: '1 semana (168 horas)',
                            value:168
                        },
                        {
                            name: '2 semanas (336 horas)',
                            value: 336
                        },
                        {
                            name: '3 semanas (504 horas)',
                            value: 504
                        },
                        {
                            name: '4 semanas (672 horas)',
                            value: 672
                        },
                        {
                            name: '1 mes (720 horas)',
                            value: 720
                        },
                        {
                            name: '6 semanas (1008 horas)',
                            value: 1008
                        },
                        {
                            name: '45 días (1080 horas)',
                            value: 1080
                        },
                        {
                            name: '7 semanas (1176 horas)',
                            value: 1176
                        },
                        {
                            name: '8 semanas (1344 horas)',
                            value: 1344
                        },
                        {
                            name: '60 días (1440 horas)',
                            value: 1440
                        },
                        {
                            name: '90 días (2160 horas)',
                            value: 2160
                        }
                    ],
                    period_types:[
                        {
                            _id: 1,
                            type: 0,
                            name: 'Nada',
                            value: 0
                        },
                        {
                            _id: 2,
                            type: 1,
                            name: 'Tasa fija',
                            value: 500
                        },
                        {
                            _id: 3,
                            type: 2,
                            name: 'Primera noche + impuestos',
                            value: 1
                        },
                        {
                            _id: 4,
                            type: 2,
                            name: '2 noches + impuestos',
                            value: 2
                        },
                        {
                            _id: 5,
                            type: 3,
                            name: '10% de la estancia + tasas',
                            value: 10
                        },
                        {
                            _id: 6,
                            type: 3,
                            name: '20% de la estancia + tasas',
                            value: 20
                        },
                        {
                            _id: 7,
                            type: 3,
                            name: '30% de la estancia + tasas',
                            value: 30
                        },
                        {
                            _id: 8,
                            type: 3,
                            name: '40% de la estancia + tasas',
                            value: 40
                        },
                        {
                            _id: 9,
                            type: 3,
                            name: '50% de la estancia + tasas',
                            value: 50
                        },
                        {
                            _id: 10,
                            type: 3,
                            name: '60% de la estancia + tasas',
                            value: 60
                        },
                        {
                            _id: 11,
                            type: 3,
                            name: '70% de la estancia + tasas',
                            value: 70
                        },
                        {
                            _id: 12,
                            type: 3,
                            name: '80% de la estancia + tasas',
                            value: 80,
                        },
                        {
                            _id: 13,
                            type: 3,
                            name: '90% de la estancia + tasas',
                            value: 90
                        },
                        {
                            _id: 14,
                            type: 3,
                            name: '100% de la estancia + tasas',
                            value: 100
                        }
                    ],
                    dates_no_refundables_old:[

                    ],
                    dates_exception_old:[

                    ],
                    rooms:[],
                    contracts:[],
                    rateConfigs_old:[],
                },


                annulment:{
                    _id: '',
                    property_id: '',
                    name: '',
                    main: false,
                    refundable: true,
                    no_refundable: false,
                    description: '',

                    cancellation_time: '',
                    in_time_value: '',
                    in_time_type: '',
                    out_time_value: '',
                    out_time_type: '',
                    dates_exception:[

                    ],
                    dates_no_refundables:[

                    ],
                    rateConfigs:[],
                    rateConfigs_all:[],
                    errors:{
                        dates_exception:false
                    },
                },


                modelValidations: {
                    name: {
                        required: true
                    },
                    description: {
                        required: true
                    },
                    period: {
                        required: true
                    },
                    inside_time: {
                        required: true
                    },
                    outside_time: {
                        required: true
                    },
                },

            }
        },

        mounted() {
            //this.fuseSearch = new Fuse(this.tableData, {keys: ['name']})
            this.changeProperty()
        },
        beforeCreate(){

        },
        async created(){
            await this.getDataAll(this.selected_property._id);
            await this.initPage(this.selected_property._id);
        },
        methods: {
            changeProperty(){

                this.initLoading();
                EventBus.$on('CHANGE_PROPERTY', async (property_id) => {
                    if(this.$route.name==='editAnnulment'){
                        this.$router.push({
                            name: 'annulments'
                        });
                    }else{
                        await this.getDataAll(property_id);
                        await this.initPage();
                    }

                })
            },
            initPage(property_id){

                this.annulment.property_id = property_id;
                this.info_page.property_id = property_id;
                this.configLogs(property_id, this.info_page.section_name);
                if(this.$route.name==='editAnnulment'){
                    let annulment_id = this.$route.params.id;
                    this.log_page.action = 1;
                    this.info_page.action = 'edit';
                    this.info_page.header = this.$t('annulment.edit.header');
                    this.info_page.button = this.$t('form.update');
                    this.breadcrumbs[this.breadcrumbs.length-1].name = this.$t('annulment.edit.header');
                    this.updateData(annulment_id);
                }else{


                    setTimeout(() => {
                        this.closeLoading();
                    }, 500);
                        //this.$refs.topProgress.done();
                }
            },
            configLogs(property_id, section_name){
                this.log_page.section = logFunctions.findSection(section_name)._id
                this.log_page.property_id = property_id;
                this.log_page.user_id = this.currentUser._id;
                this.log_page.action = 0;
                this.log_page.date = moment().format('YYYY-MM-DD HH:mm');
            },
            async createLog(object_id, action, data = []){
                let paramsLog= this.log_page;
                paramsLog.object_id = object_id;
                //paramsLog.section = logFunctions.findSection(this.info_page.section_name)._id
                paramsLog.action = action;
                paramsLog.data = data;
                paramsLog.date = moment().format('YYYY-MM-DD HH:mm:ss');
                await logService.create(paramsLog);
            },

            async getDataAll(property_id){
                this.annulment.property_id = property_id;
                this.info_page.property_id = property_id;
            },

            updateData(annulment_id) {
                annulmentService.getAnnulmentId(annulment_id).then(response => {
                    let data = response.data.data.cancellationPolicy;
                   //console.log(data, 'data')
                    this.info_page.original_info =  JSON.parse(JSON.stringify(data))
                    let annulment = this.annulment
                    let refundable = data.refundable

                    annulment._id = data._id;
                    annulment.property_id = data.property_id;
                    annulment.name = data.name;
                    annulment.description = data.description;
                    annulment.main = data.main;
                    annulment.in_time_value = data.in_time_value;

                    annulment.in_id = (refundable)?(this.info_page.period_types.find(item=> item.type===data.in_time_type && item.value===data.in_time_value))._id:'';
                    annulment.out_id = (refundable)?(this.info_page.period_types.find(item=> item.type===data.out_time_type && item.value===data.out_time_value))._id:'';
                    annulment.out_time_value = (refundable)?data.out_time_value:'';
                    annulment.in_time_type = (data.refundable)?data.in_time_type:'';
                    annulment.out_time_type = (data.refundable)?data.out_time_type:'';
                    annulment.cancellation_time = (data.refundable)?data.cancellation_time:'';
                    annulment.refundable = data.refundable;
                    annulment.no_refundable = (data.refundable)?false:true;

                    if(data.refundable){
                        data.refundable_dates.forEach(item =>{
                            this.info_page.dates_no_refundables_old.push(item._id);
                            annulment.dates_no_refundables.push({
                                _id:item._id,
                                from: [moment(item.from , 'YYYY-MM-DD'), moment(item.to, 'YYYY-MM-DD')],
                                to: item.to,
                            });
                        });
                        data.season_exception_dates.forEach(item =>{
                           //console.log('item', item);
                            this.info_page.dates_exception_old.push(item._id);
                            annulment.dates_exception.push({
                                _id:item._id,
                                from: [moment(item.from, 'YYYY-MM-DD'), moment(item.to, 'YYYY-MM-DD')],
                                to: item.to,
                                cancellation_time: item.cancellation_time,
                                in_id: (this.info_page.period_types.find(element=> element.type===item.in_time_type && element.value===item.in_time_value))._id,
                                out_id: (this.info_page.period_types.find(element=> element.type===item.out_time_type && element.value===item.out_time_value))._id,
                            });
                        });
                    }
                    this.closeLoading();
                });
                let contracts_array = [];

                /*contractService.getRateConfig({cancellation_policy:annulment_id}).then( response=> {

                    let rateConfigs = response.data.data.rateconfigs;
                    this.annulment.rateConfigs_all = rateConfigs;

                    let rooms = [...new Set(rateConfigs.map(x=>x.room))]
                    rooms.forEach(room =>{
                       let finded = rateConfigs.filter(x=>x.room === room);
                        let contract_array = [];
                        finded.forEach(elem=>{

                            let info_contract = this.info_page.contracts.find(x => x._id === elem.rateplan);
                            let rateConfig = {
                                name : info_contract.name,
                                rateconfig_id : elem._id,
                                status: true
                            }
                            contract_array.push(rateConfig);
                        })
                        let object_aux = {
                            room : this.info_page.rooms.find(item=> item._id === room).name,
                            rateConfigs: contract_array
                        }

                        contracts_array.push(object_aux)
                    })

                    this.annulment.rateConfigs = contracts_array;
                    this.$refs.topProgress.done();
                    //console.log('rateConfig', contracts_array)
                })*/


            },
            sendForm(action){
                this.validate(action);
            },
            async saveItem(){
                let annulment = this.annulment;
                this.initLoading('Guardando')

                if(annulment.refundable){
                    let item_in = this.info_page.period_types.find(period => period._id === annulment.in_id);
                    let item_out = this.info_page.period_types.find(period => period._id === annulment.out_id);
                    //console.log(item.type, 'itm', annulment.in_time_val)
                    annulment.in_time_type = item_in.type;
                    annulment.out_time_type = item_out.type;
                    annulment.in_time_value = item_in.value;
                    annulment.out_time_value = item_out.value;
                }else{
                    annulment.in_time_value = null;
                    annulment.in_time_type = null;
                    annulment.out_time_valueue = null;
                    annulment.out_time_type = null;
                    annulment.cancellation_time = null;
                    annulment.dates_no_refundables = [];
                    annulment.dates_exception = [];
                }

                let new_annulment = null;
                let datesNoRefundables = [];
                let datesException = [];
                annulment.dates_no_refundables.forEach(async item => {
                    datesNoRefundables.push({
                        from: item.from[0],
                        to: item.from[1],
                    });
                });
                annulment.dates_exception.forEach(async item => {
                    let item_in = this.info_page.period_types.find(period => period._id === item.in_id);
                    let item_out = this.info_page.period_types.find(period => period._id === item.out_id);
                    //console.log('dates_exception', item, item_in, item_out)
                    datesException.push({
                        from: moment(item.from[0]).format('YYYY-MM-DD'),
                        to: moment(item.from[1]).format('YYYY-MM-DD'),
                        cancellation_time: item.cancellation_time,
                        in_time_type: item_in.type,
                        out_time_type: item_out.type,
                        in_time_value: item_in.value,
                        out_time_value: item_out.value
                    });
                });
                annulment.refundable_dates = datesNoRefundables;
                annulment.season_exception_dates = datesException;


                annulmentService.createAnnulment(annulment).then( async response=>{

                    new_annulment = response.data.data.cancellationPolicy;
                    await this.createLog(new_annulment._id, 0)
                   //console.log('reponse =>', new_annulment);

                   /* annulment.dates_no_refundables.forEach(async item => {
                        item = {
                            _id: item._id,
                            start: item.start[0],
                            end: item.start[1],
                        }
                       //console.log('dates_no_refundables', item)
                        await annulmentService.createRefundableDate(item, new_annulment._id);
                    });
                    annulment.dates_exception.forEach(async item => {
                        let item_in = this.info_page.period_types.find(period => period._id === item.in_id);
                        let item_out = this.info_page.period_types.find(period => period._id === item.out_id);
                       //console.log('dates_exception', item, item_in, item_out)
                        let item_new = {
                            _id: item._id,
                            from: moment(item.start[0]).format('YYYY-MM-DD'),
                            to: moment(item.start[1]).format('YYYY-MM-DD'),
                            cancellation_time: item.cancellation_time,
                            in_time_type: item_in.type,
                            out_time_type: item_out.type,
                            in_time_value: item_in.value,
                            out_time_value: item_out.value
                        }
                        await annulmentService.createExceptionDate(item_new, new_annulment._id);
                    });*/

                    this.showSwal(this.$t('annulment.new.msg'));
                    this.closeLoading();
                }).catch(error => {

                    this.closeLoading();
                    if(error.response.data){
                       //console.log('error.response.data', error);
                        if(error.response.data.err === "CancellationPolicy validation failed: name: name debe ser único"){
                            this.alertError();
                        }
                    }
                }).finally(()=>{


                })

            },
            updateItem(){

                this.initLoading('Guardando')
                let annulment = this.annulment;
                let responseError = true;
                /*let trash_rateConfigs = annulment.rateConfigs_all.filter(x=> x.status === false);
                //console.log('annulment', trash_rateConfigs, trash_rateConfigs.length);
                trash_rateConfigs.forEach(async item => {
                    //await contractService.desactiveRateConfig(item._id);
                    await contractService.deletetRateConfig(item._id);
                });*/

                if(annulment.refundable){

                    let item_in = this.info_page.period_types.find(period => period._id === annulment.in_id);
                    let item_out = this.info_page.period_types.find(period => period._id === annulment.out_id);


                    /*let item_in = this.info_page.period_types.find(period => period.value === annulment.in_time_val);
                    let item_out = this.info_page.period_types.find(period => period.value === annulment.out_time_val);*/
                    //console.log(item.type, 'itm', annulment.in_time_val)
                    annulment.in_time_type = item_in.type;
                    annulment.out_time_type = item_out.type;
                    annulment.in_time_value = item_in.value;
                    annulment.out_time_value = item_out.value;
                    annulment.refundable = true;
                }else{
                    annulment.in_time_value = null;
                    annulment.in_time_type = null;
                    annulment.out_time_value = null;
                    annulment.out_time_type = null;
                    annulment.cancellation_time = null;
                    annulment.dates_no_refundables = [];
                    annulment.dates_exception = [];
                }

                let datesNoRefundables = [];
                let datesException = [];
                annulment.dates_no_refundables.forEach(async item => {
                    datesNoRefundables.push({
                        from: item.from[0],
                        to: item.from[1],
                    });
                });
                annulment.dates_exception.forEach(async item => {
                    let item_in = this.info_page.period_types.find(period => period._id === item.in_id);
                    let item_out = this.info_page.period_types.find(period => period._id === item.out_id);
                    //console.log('dates_exception', item, item_in, item_out)
                    datesException.push({
                        from: moment(item.from[0]).format('YYYY-MM-DD'),
                        to: moment(item.from[1]).format('YYYY-MM-DD'),
                        cancellation_time: item.cancellation_time,
                        in_time_type: item_in.type,
                        out_time_type: item_out.type,
                        in_time_value: item_in.value,
                        out_time_value: item_out.value
                    });
                });
                annulment.refundable_dates = datesNoRefundables;
                annulment.season_exception_dates = datesException;


                annulmentService.updateAnnulment(annulment)
                    .then(async (response) => {

                        let responsePolitic = response.data.data.cancellationPolicy;
                        let changes = logFunctions.cheackProp(this.info_page.original_info, responsePolitic, this.info_page.section_name)

                        console.log('roomResponse', responsePolitic);
                        if(changes.length){
                            await this.createLog(this.annulment._id, 1, changes);
                        }

                       /* let dates_no_refundables_old = this.info_page.dates_no_refundables_old;
                        let dates_exception_old = this.info_page.dates_exception_old;

                       //console.log('old', dates_no_refundables_old, dates_exception_old)
                        //let rateConfigs_old = this.info_page.rateConfigs_old;
                        //let rateConfigs = this.checkConfigsActives();

                        annulment.dates_no_refundables.forEach(async item => {
                            let index = dates_no_refundables_old.indexOf(item._id);
                            (index>=0)?dates_no_refundables_old.splice(index, 1):'';

                            item = {
                                _id: item._id,
                                start: item.start[0],
                                end: item.start[1],
                            }

                            // console.log('***dates_no_refundables', item);
                            // console.log('***dates_no_refundables', moment(item.end).format('YYYY-MM-DD'));

                            if(item._id!==null){
                                await annulmentService.updateRefundableDate(item, annulment._id);
                            }else{

                                await annulmentService.createRefundableDate(item, annulment._id);
                            }
                        });

                        annulment.dates_exception.forEach(async item => {
                            let item_in = this.info_page.period_types.find(period => period._id === item.in_id);
                            let item_out = this.info_page.period_types.find(period => period._id === item.out_id);

                            let index = dates_exception_old.indexOf(item._id);
                            (index>=0)?dates_exception_old.splice(index, 1):'';
                            let item_new = {
                                _id: item._id,
                                from: moment(item.start[0]).format('YYYY-MM-DD'),
                                to: moment(item.start[1]).format('YYYY-MM-DD'),
                                cancellation_time: item.cancellation_time,
                                in_time_type: item_in.type,
                                out_time_type: item_out.type,
                                in_time_value: item_in.value,
                                out_time_value: item_out.value
                            }
                            if(item._id!==null){
                                await annulmentService.updateExceptionDate(item_new, annulment._id);
                            }else{
                                await annulmentService.createExceptionDate(item_new, annulment._id);
                            }
                        });

                        //console.log('old2', dates_no_refundables_old, dates_exception_old)

                        dates_no_refundables_old.forEach(async (date_id, idx) => {
                            await annulmentService.deleteRefundableDate(annulment._id, date_id);
                        });
                        dates_exception_old.forEach(async (date_id, idx) => {
                            await annulmentService.deleteExceptionDate(annulment._id, date_id);
                        });*/
                        /*rateConfigs_old.forEach(async (item) => {
                            await contractService.desactiveRateConfig(item);
                        });*/




                    })
                    .catch(error => {
                        this.closeLoading();
                        if(error.response.data.err === "Validation failed: name: name debe ser único"){
                            this.alertError();
                            responseError = false;
                        }
                       //console.log('error.response.data', error.response.data);
                    })
                    .finally((response) => {
                        if(responseError){
                            this.closeLoading();
                            this.showSwal(this.$t('annulment.edit.msg'));
                        }
                    });
            },
            showSwal (msg) {

                swal({
                    title: this.$t('contract.new.modal'),
                    text: msg,
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    type: 'success'
                }).then(()=> {
                    this.$router.push({
                        name: 'annulments'
                    });
                });
            },


            alertError(){
                swal({
                    title: 'La política de cancelación ya existe',
                    type: 'warning',
                    confirmButtonClass: 'btn btn-danger btn-fill',
                    confirmButtonText: 'Modificar',
                    buttonsStyling: false
                }).then((result)=> {

                });
            },

            getError (fieldName) {
                return this.errors.first(fieldName);
            },
            validate (action) {
               //console.log('action?', action)
                this.customValidity();
                this.$validator.validateAll().then(isValid => {

                    if(isValid){
                        if( this.customValidity()){
                            if(action==='add'){
                                this.saveItem();
                            }else{
                                this.updateItem();
                            }
                        }
                    }
                   //console.log('validado?', isValid)
                })
            },

            customValidity(){
                let error_response = true;


                let dates_exception = this.annulment.dates_exception;


                let errores = this.annulment.errors;
                errores.dates_exception = false;




                dates_exception.forEach((dateExc, idx)=>{
                   //console.log('dateExc', dateExc)

                    let arrayValidate = [dateExc.cancellation_time, dateExc.in_id, dateExc.out_id];
                    if(dateExc.from!==null){
                        arrayValidate.push(dateExc.from[0])
                        arrayValidate.push(dateExc.from[1])
                    }else{
                        error_response = false;
                        errores.dates_exception = true;
                    }

                    if(this.validadorCustom(arrayValidate, 'string')){
                        error_response = false;
                        errores.dates_exception = true;
                    }
                })
               //console.log('error_response', error_response)
                return error_response;
            },

            validadorCustom(fields, type){
                let booleanStatus = false;
                fields.forEach(field=>{
                    if(type === 'string'){
                        field = field+'';
                        if(field==='null' ||  field==='' || field.trim().length === 0 ){
                            booleanStatus = true ;
                        }
                    }
                    if(type==='number'){
                        if(isNaN(parseFloat(field)) || !isFinite(field)){
                            booleanStatus = true ;
                        }
                    }
                });

                return booleanStatus;

            },
            addDate(type){
                let item = {};
                switch (type) {

                    case 'refundable':
                        item = JSON.parse(JSON.stringify(this.info_page.model_dates));
                        this.annulment.dates_no_refundables.push(item);
                        break;
                    case 'exception':
                        item = JSON.parse(JSON.stringify(this.info_page.model_date_exception));
                        this.annulment.dates_exception.push(item)
                        break;

                }
            },
            deleteDate(type, n){
                switch (type) {
                    case 'refundable':
                        this.annulment.dates_no_refundables.splice(n, 1);
                        break;
                    case 'exception':
                        this.annulment.dates_exception.splice(n, 1);
                        break;

                }
            },
            initLoading(text = 'Cargando...') {
                this.screenLoading = true;
                this.screenText = text;
            },
            closeLoading(){
                this.screenLoading = false;
            }
        }
    }

</script>
<style lang="scss">


    .h-title{
        margin-bottom: 0;

    }
    .box-button{
        //padding-left: 10px;
        .add-item{
            width: 100% ;
            height: 40px;
            color: #fff;
            background-color: #222222;
            border: 2px solid #000;
        }
    }
    .btn-action {
        line-height: 45px;
        font-size: 25px;
        margin-left: 15px;
    }
    .card{
        .lbl-main{
            font-weight: 600;
            margin-top: 50px;
        }

        .pt-custom{
            margin-top: 26px !important;
        }

    }
    .form-check  {
        margin-bottom: 0;
        .text-info{
            margin-bottom: 0;
        }
        &.text-none{
            .form-check-label{
                text-transform: none;
            }
        }
    }
    .inline-form{
        margin-top: 35px;
    }
    .box-header{
        padding-left: 15px;
        label{
            margin-left: 7px;
            font-size: 11px;
        }
    }
    .box-items{
        padding-left: 15px;
        &.checkeds{
            margin-bottom: 40px;
        }
        .el-collapse{
            border-top: 0;
        }
        .box-checkeds{
            padding: 15px 15px 0;
        }
        .item-minus{
            line-height: 40px;
            margin-left: 15px;
        }
        .form-group{
            margin-bottom: 0;
        }
    }
    .custom-error{
        label.text-danger{
            text-transform: none;
            white-space: nowrap;
        }
    }
</style>
